import { DefaultLang } from './State'

import State from './State'
import { GetText } from './Lang'

const GlobalHttpRequestTimeout = 5000;
const HttpHost = "https://backend.quaind.com/";
export const ApiDocsLink = "https://api-docs.quaind.com/docs/quaind-api/";

export const FormatString = (label, values) => {
    for (var i=0; i < values.length; i++) {
        label = label.replace("{" + i + "}", values[i]);
    }
    return label;
}

export function SetDocumentTitleAndMeta(namespace, titlePlaceholders=undefined, descPlaceholders=undefined) {
    if (typeof titlePlaceholders === "undefined") {
        document.title = GetText(namespace, 'title');
        document.querySelector('meta[property="og:title"]').setAttribute("content", GetText(namespace, 'title'));
    }
    else {
        document.title = FormatString(GetText(namespace, 'title'), [titlePlaceholders]);
        document.querySelector('meta[property="og:title"]').setAttribute("content", FormatString(GetText(namespace, 'title'), [titlePlaceholders]));
    }
    if (typeof descPlaceholders === 'undefined') {
        document.querySelector('meta[name="description"]').setAttribute("content", GetText(namespace, 'description'));
        document.querySelector('meta[property="og:description"]').setAttribute("content", GetText(namespace, 'description'));
    }
    else {
        document.querySelector('meta[name="description"]').setAttribute("content", FormatString(GetText(namespace, 'description'), descPlaceholders));
        document.querySelector('meta[property="og:description"]').setAttribute("content", FormatString(GetText(namespace, 'description'), descPlaceholders));
    }
}

export function Url(page = '') {
    let query = '/';
    if (State.lang !== DefaultLang) {
        query += State.lang + '/';
    }
    query += page;
    return query;
}

export const SendHttpRequest = (url, settings = {}, timeout=GlobalHttpRequestTimeout) => {
    const requestSettings = Object.assign({}, settings, {
        headers: { 
            'Accept': 'application/json'
        }
    });
    if (typeof localStorage.session !== 'undefined') {
        requestSettings.headers['XWeb'] = true;
        requestSettings.headers['Token'] = localStorage.session;
    }
    return new Promise((resolve, reject) => {
        let didTimeOut = false;
        let timer = setTimeout(() => {
            didTimeOut = true;
            //ErrorPage(GetText('explanation', 'pageDataRetrievalError'))
            reject(new Error('Request timed out'));
        }, timeout);
        fetch(HttpHost + url, requestSettings)
        .then((response) => {
            clearTimeout(timer);
            if ((response.status === 200) && !didTimeOut) {
                response.json().then((data) => {
                    if (data.session) {
                        localStorage.session = data.session;
                    }
                    resolve(data);
                    /*
                    if (data.errorCode) {
                        switch (data.errorCode) {
                            case 'GRACE_PERIOD_NOT_FULFILLED':
                                var date = new Date(data.params[0] * 1000);
                                //ErrorPage(FormatString(GetText('explanation', 'alreadyRated'), [date.toLocaleString(), FormatSecondsToDaysHoursMinsSeconds(data.params[1])]));
                                break;
                            default:
                                ErrorPage(FormatString(GetText('explanation', data.errorCode), [data.params]));
                                break;
                        }
                    }
                    else {
                        resolve(data);
                    }*/
                }).catch(() => {
                    //ErrorPage(GetText('explanation', 'pageDataRetrievalError'));
                    reject(new Error('Unknown error occured'));
                });
            }
            else {
                //ErrorPage(GetText('explanation', 'pageDataRetrievalError'));
                reject(new Error('Request timed out'));
            }
        })
        .catch((err) => {
            if (didTimeOut) return;
            //ErrorPage(GetText('explanation', 'pageDataRetrievalError'));
            reject(err);
        });
    });
}

export const IdentifyEmbedHost = () => {
    var href = (window.location !== window.parent.location)
            ? document.referrer
            : "";
            
    
    return ParseHost(href);
}

export const ParseHost = (href) => {
    try {
        let url = new URL(href);
        if (url.protocol.startsWith('http')) {
            let host = url.host.replace('www.', '');
            if (/^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}$/.test(host)) {
                return host;
            }
            else {
                return null;
            }
        }
    }
    catch (error) {
        return null;
    }
}

export function normFleschReadingEase(value) {
    // 100 very easy, 0 extremally hard to read
    if (value > 85) {
        return 0;
    }
    if (value > 70 && value < 85) {
        return 1;
    } 
    if (value > 60 && value < 70) {
        return 2;
    }
    if (value > 30 && value < 60) {
        return 3;
    }
    if (value < 30) {
        return 4;
    }
}

export function secondsToFormattedString(seconds) {
    var days = Math.floor(seconds / (3600 * 24));
    seconds  -= days * 3600 * 24;
    var hrs   = Math.floor(seconds / 3600);
    seconds  -= hrs * 3600;
    var mnts = Math.floor(seconds / 60);
    seconds  -= mnts * 60;
    let parts = [];

    if (days > 0 || parts.length !== 0) parts.push(days);
    if (hrs > 0 || parts.length !== 0) parts.push(hrs);
    if (mnts > 0 || parts.length !== 0) parts.push(mnts);
    if (seconds > 0 || parts.length !== 0) parts.push(seconds);

    if (parts.length === 1) {
        return '{0}s'.replace('{0}', parts[0]);
    }
    if (parts.length === 2) {
        return '{0}m {1}s'.replace('{0}', parts[0]).replace('{1}', parts[1]);
    }
    if (parts.length === 3) {
        return '{0}h {1}m {2}s'.replace('{0}', parts[0]).replace('{1}', parts[1]).replace('{2}', parts[2]);
    }
    if (parts.length === 4) {
        return '{0}d {1}h {2}m {3}s'.replace('{0}', parts[0]).replace('{1}', parts[1])
            .replace('{2}', parts[2]).replace('{3}', parts[3]);
    }
    return seconds;
}

const gradeLevelLabels = {
    '1': '1st grade',
    '2': '2nd grade',
    '3': '3rd grade',
    '4': '4th grade',
    '5': '5th grade',
    '6': '6th grade',
    '7': '7th grade',
    '8': '8th grade',
    '9': '9th grade',
    '10': '10th grade',
    '11': '11th grade',
    '12': '12th grade',
    '13': '13th grade',
    '14': '14th grade',
    '15': '15th grade',
}

export function SMOGGradeToLabel(grade) {
    grade = Math.round(grade)
    return grade in gradeLevelLabels ? gradeLevelLabels[grade] : '.';
}

export function LinsearWriteToLabel(grade) {
    grade = Math.round(grade)
    return grade in gradeLevelLabels ? gradeLevelLabels[grade] : '.';
}

export function FleschKincaidToLabel(grade) {
    grade = Math.round(grade)
    return grade in gradeLevelLabels ? gradeLevelLabels[grade] : '.';
}

export function AutomatedReadabilityIndexToLabel(grade) {
    grade = Math.round(grade)
    return grade in gradeLevelLabels ? gradeLevelLabels[grade] : '.';
}

export function GunningFogToLabel(grade) {
    grade = Math.round(grade)
    return grade in gradeLevelLabels ? gradeLevelLabels[grade] : '.';
}

export function DaleChallToLabel(grade) {
    if (grade > 9.7) return gradeLevelLabels[15];
    if (grade > 9.4) return gradeLevelLabels[14];
    if (grade > 9.0) return gradeLevelLabels[13];

    if (grade > 8.45) return gradeLevelLabels[12];
    if (grade > 8.0) return gradeLevelLabels[11];

    if (grade > 7.45) return gradeLevelLabels[10];
    if (grade > 7.0) return gradeLevelLabels[9];

    if (grade > 6.45) return gradeLevelLabels[8];
    if (grade > 6.0) return gradeLevelLabels[7];

    if (grade > 5.45) return gradeLevelLabels[6];
    if (grade > 5.0) return gradeLevelLabels[5];

    if (grade <= 4.9) return gradeLevelLabels[4];
    return '.'
}

export function ColemanLiauToLabel(grade) {
    grade = Math.round(grade);
    return grade in gradeLevelLabels ? gradeLevelLabels[grade] : '.';
}

export function FleschReadingEaseToLabel(grade) {
    if (grade < 10) return 'Professional';
    if (grade < 30) return 'College graduate';
    if (grade < 50) return 'College';
    if (grade < 60) return '10th to 12th grade';
    if (grade < 70) return '8th to 9th grade';
    if (grade < 80) return gradeLevelLabels[7];
    if (grade < 90) return gradeLevelLabels[6];

    return gradeLevelLabels[5];
}