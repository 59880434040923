import React from 'react'

const AggressionHeatMap = (data) => {
    let aggression = data.aggression['breakdown']
    return (
        <p>
        {
        data.tokens.map((item, index) => {
            
            let level = 0;
            let currentVal = aggression[index]['aggression'];

            if (currentVal > 0.8) {
                level = 2;
            } else if (currentVal > 0.4) {
                level = 1;
            }

            if (index === 0) {
                return <span className={`aggression-heatmap sent level-`+ level} key={index}>{item}</span>
            } else {
                return <span key={index}>&nbsp;<span className={`aggression-heatmap sent level-`+ level} key={index}>{item}</span></span>
            }
            }
        )
        }
        </p>
    )
}

export default AggressionHeatMap;