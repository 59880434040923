
import React, {useState, useEffect} from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Url, ApiDocsLink } from '../Utils'
import LogoOriginal from '../icons/logo-quaind-original.svg'
import LogoLight from '../icons/logo-quaind-light.svg'
import { GetText } from '../Lang'

const Navigation = (props) => {

    const [light, setLight] = useState(false);
    const handleScroll = (event) => {
        if (event.srcElement.documentElement.scrollTop > 50) {
            setLight(true);
        }
        else {
            setLight(false);
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [light]);

    return (
        <>
            <Navbar variant={light ? 'w3-light' : 'w3'} className="sticky-top" expand="lg">
                <div className="container">
                    <Navbar.Brand href={Url('')} className="mr-5">
                        <img
                            src={light ? LogoOriginal : LogoLight}
                            width="140px"
                            height="30px"
                            className="d-inline-block align-top"
                            alt="QUAIND – Natural Language AI"
                        />
                        <span className="badge" style={{'marginTop':'3px', 'marginLeft': '24px'}}>Beta</span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto container justify-content-end font-weight-bold">
                            <Nav.Link href={Url('')} className={props.homeActive + " px-3"}>{GetText('menu', 'product')}</Nav.Link>
                            <Nav.Link href={Url('Features/')} className={props.featuresActive + " px-3"}>{GetText('menu', 'features')}</Nav.Link>
                            <Nav.Link href={Url('UseCases/')} className={props.usesCasesActive + " px-3"}>{GetText('menu', 'usecases')}</Nav.Link>
                            <Nav.Link href={ApiDocsLink} className={"px-3"}>{GetText('menu', 'api')}</Nav.Link>
                            <Nav.Link href={Url('Contact/')} className={props.contactActive + " px-3"}>{GetText('menu', 'contactus')}</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        </>
    );
}

export default Navigation;