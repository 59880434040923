import React, {useState} from 'react'
import { SendHttpRequest } from '../Utils'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Error from './Error'
import { GetText } from '../Lang'
import ReactGA from 'react-ga'

const handleSubmit = (event, data, setThank, setError) => {
  event.preventDefault();
  ReactGA.event({
    category: 'Contact',
    action: 'Submitted Form'
  })
  SendHttpRequest('Contact', { 
      method: "POST", 
      body: JSON.stringify(data)}
  ).then(function(data) {
      data.errorCode ? setError(true) : setThank(true);
  }).catch(() => setError(true));
}

const ThankYou = () => {
  return (
      <>
        <h2>{GetText('thankYou', 'header')}</h2>
        <p className="lead">{GetText('thankYou', 'explanation')}</p>
      </>
  );
}
  
const Contact = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [thank, setThank] = useState(false);
  const [error, setError] = useState(false);
  const [solutions, setSolutions] = useState([]);

  const handleSolutionCheckboxChanges = (event) => {
    const name = event.target.name;
    const checked = event.target.checked;
    let clone = [...solutions];
    let indexOfItem = solutions.indexOf(name);
    if (checked) {
      if (indexOfItem === -1) {
        clone.push(name);
      }
    } else {
      clone.splice(indexOfItem, 1);
    }

    setSolutions(clone);
  }

  return (
    <>
    {thank ? <ThankYou/> : error ? <Error message={`Error occured`}/> :
    <>
        <h2>{`Get In Touch`}</h2>
        <Form onSubmit={(event) => handleSubmit(event, {
            message: message,
            email: email,
            solutions: solutions
        }, setThank, setError)}>
          
          <Form.Group>
            <Form.Label>
              What solutions are you interested in?
            </Form.Label>

            <Form.Check 
              type={`checkbox`}
              name={`api`}
              id={`api`}
              label={`REST API`}
              onChange={handleSolutionCheckboxChanges}
            />

            <Form.Check 
              type={`checkbox`}
              name={`website`}
              id={`website`}
              label={`Website Analysis`}
              onChange={handleSolutionCheckboxChanges}
            />

            <Form.Check 
              type={`checkbox`}
              name={`dashboard`}
              id={`dashboard`}
              label={`Dashboard & Analysis tools`}
              onChange={handleSolutionCheckboxChanges}
            />

            <Form.Check 
              type={`checkbox`}
              name={`plugins`}
              id={`plugins`}
              label={`Browser Plugins`}
              onChange={handleSolutionCheckboxChanges}
            />
            
            <Form.Check 
              type={`checkbox`}
              name={`other`}
              id={`other`}
              label={`Other`}
              onChange={handleSolutionCheckboxChanges}
            />
          </Form.Group>

          <Form.Group controlId="formGridMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control required as="textarea" rows="3" placeholder={`Write your message here`} value={message} onChange={(event) => {setMessage(event.target.value)}}/>
          </Form.Group>

          <Form.Group controlId="formGridEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" required placeholder={`Your email`} value={email} onChange={(event) => setEmail(event.target.value)} />
          </Form.Group>

          <Button variant="primary" className="block-md float-right" type="submit">{`Submit`}</Button>
        </Form>
        </>
    }
    </>
  );
}

export default Contact;