import React from 'react'
import {normFleschReadingEase} from '../../Utils'

const ReadabilityHeatMap = (data) => {
    let readability = data.readability['breakdown']
    return (
        <p>
        {
            data.tokens.map((item, index) => {
                    let currentVal = readability[index];
                    let normVal = normFleschReadingEase(currentVal);
                    if (index === 0) {
                        return <span className={`readability-heatmap sent level-`+ normVal} key={index}>{item}</span>
                    } else {
                        return <span key={index}>&nbsp;<span className={`readability-heatmap sent level-`+ normVal} key={index}>{item}</span></span>
                    }
                }
            )
        }
        </p>
    )
}

export default ReadabilityHeatMap;