import React, { useState } from 'react'
import Chart from "react-google-charts"
import ModeDropdown from './ModeDropdown'

const normalizeTimeValues = (durations, values, interval) => {
  let durationsClone = [...durations];
  let tmpDuration = 0;
  let valueIndex = 0;
  let allNewDurationObjects = [];
  let newDurationObjectParts = [];
  while (durationsClone.length) {
    tmpDuration += durationsClone[0];
    if (tmpDuration >= interval) {
      let diff = tmpDuration - interval;
      let tmp = {
        'duration': durationsClone[0] - diff,
        'value': values[valueIndex]
      }
      newDurationObjectParts.push(tmp);
      allNewDurationObjects.push(newDurationObjectParts);
      newDurationObjectParts = [];
      tmpDuration = 0;
      durationsClone.splice(0, 1);
      if (diff > 0) {
        durationsClone.unshift(diff);
      } else {
        valueIndex++;
      }
    } else {
      let tmp = {
        'duration': durationsClone[0],
        'value': values[valueIndex]
      }
      newDurationObjectParts.push(tmp);
      durationsClone.splice(0, 1);
      valueIndex++;
    }
  }
  if (newDurationObjectParts.length) {
    allNewDurationObjects.push(newDurationObjectParts);
  }

  let result = [];
  for (let i=0; i < allNewDurationObjects.length; i++) {
    let tmp = 0.0;
    for (let j=0; j < allNewDurationObjects[i].length; j++) {
      tmp += (allNewDurationObjects[i][j].duration / interval) * allNewDurationObjects[i][j].value;
    }
    result.push(tmp);
  }

  return result;
}

const convertDataPointsToIntervals = (points, values) => {
  let intervals = [];
  let tmp = 0;
  for (let i=0; i< points.length; i++) {
    tmp += points[i];
    intervals.push(tmp);
  }

  let result = [];
  for (let i=values.length - 1; i >=0; i--) {
    result.push([intervals[i], values[i]])
    if (i === 0) {
      result.push([0, values[i]])
    } else {
      result.push([intervals[i - 1], values[i]])
    }
  }
  return result.reverse()
}

export const SentimentChartStepped = (props) => {
  const durations = props.data.readTime.breakdown;
  let sentimentValues = [];
  for (let i=0; i < props.data.sentiment.breakdown.length; i++) {
    sentimentValues.push(props.data.sentiment.breakdown[i]['compound'])
  }
  
  let chartData = convertDataPointsToIntervals(durations, sentimentValues);
  chartData.unshift(["Read time", "Sentiment"])
  
  return <>
      <Chart
        chartType="LineChart"
        width="100%"
        height="400px"
        data={chartData}
        options={
          {
            pointSize: 5,
            chart: {
              title: "Sentiment Chart",
              subtitle: "Add subtitle here."
            },
            hAxis: {
              title: null,
              format: '#',
            },
            vAxis: {
              title: 'Sentiment',
              ticks: [{v: -1, f: 'Negative'}, {v: 0, f: 'Neutral'}, {v: 1, f: 'Positive'}],
            },
          }
        }
      />
      <ModeDropdown/>
  </>
}

export const SentimentChartSteppedAndNormalized = (props) => {
  const durations = props.data.readTime.breakdown;
  let sentimentValues = [];
  for (let i=0; i < props.data.sentiment.breakdown.length; i++) {
    sentimentValues.push(props.data.sentiment.breakdown[i]['compound'])
  }

  const interval = props.interval
  let normalizedSentiments = normalizeTimeValues(durations, sentimentValues, interval); 

  let newIntervals = []
  for (let i = 0; i < normalizedSentiments.length; i++) {
    newIntervals.push(interval);
  }
  
  let chartData = convertDataPointsToIntervals(newIntervals, normalizedSentiments);
  chartData.unshift(["Read time", "Sentiment"])

  return (
      <Chart
        chartType="LineChart"
        width="100%"
        height="400px"
        data={chartData}
        options={
          {
            pointSize: 5,
            chart: {
              title: "Sentiment Chart",
              subtitle: "Add subtitle here."
            },
            hAxis: {
              title: 'Read time',
              format: '#',
            },
            vAxis: {
              title: 'Sentiment',
              ticks: [{v: -1, f: 'Negative'}, {v: 0, f: 'Neutral'}, {v: 1, f: 'Positive'}],
            },
          }
        }
      />
  )
}


function dataPointToLabel(value) {
  if (value > 0.9) {
    return 'Positive';
  }
  if (value > 0.5) {
    return 'Possibly positive';
  }

  if (value < -0.9) {
    return 'Negative';
  }

  if (value < -0.5) {
    return 'Possibly negative';
  }

  if (value < 0.2 && value > -0.2) {
    return 'Neutral';
  }

  return 'Possibly neutral';
}

const SentimentChart = (props) => {
    const [mode, setMode] = useState('read');

    const durations = props.data[mode + 'Time'].breakdown;
    let chartData = []
    let tmp = 0;
    for (let i=0; i < props.data.sentiment.breakdown.length; i++) {
      tmp += durations[i]
      let value = props.data.sentiment.breakdown[i]['compound']
      chartData.push([tmp, value, props.data.tokens[i] + ' [' + dataPointToLabel(value) + ']'])
    }
    
    chartData.unshift([mode + " time", "Sentiment flow", {'role': 'tooltip'}])
    return <div style={{'height': '400px'}}>
      <Chart
        width="100%"
        height="400px"
        chartType="SteppedAreaChart"
        loader={<div>Loading Chart</div>}
        data={chartData}
        options={{
          tooltip: {isHtml: true},
          chartArea: {
            left: 100,
            top: 40,
            width: '100%',
          },
          connectSteps: false,
          colors: ['#5A5BE9'],
          // areaOpacity: 0,
          hAxis: {
            title: 'Time (in seconds)',
            format: '#',
          },
          legend: 'none',
          vAxis: {
            title: 'Sentiment',
            ticks: [{v: -1, f: 'Negative'}, {v: 0, f: 'Neutral'}, {v: 1, f: 'Positive'}],
          },
        }}
      />
      <ModeDropdown id="sentiment" onSelectCallback={(key) => {setMode(key)}}/>
    </div>
  }

  export default SentimentChart;