import React, { useState } from 'react'
import Chart from 'react-google-charts'
import ModeDropdown from './ModeDropdown'

function dataPointToLabel(value) {
  if (value > 0.75) {
    return 'Aggressive';
  }
  if (value < 0.75 && value > 0.25) {
    return 'Possibly aggressive';
  }
  if (value < 0.25 && value > 0.1) {
    return 'Possibly neutral';
  }

  return 'Neutral';
}

const AggressionChart = (props) => {
    const [mode, setMode] = useState('read');

    const durations = props.data[mode + 'Time'].breakdown;
    let chartData = []
    let tmp = 0;
    for (let i=0; i < props.data.aggression.breakdown.length; i++) {
      tmp += durations[i]
      let value = props.data.aggression.breakdown[i]['aggression']
      chartData.push([tmp, value, props.data.tokens[i] + ' [' + dataPointToLabel(value) + ']'])
    }
    
    chartData.unshift([mode + " time", "Aggressiveness", {'role': 'tooltip'}])
    return <div style={{'height': '400px'}}>
      <Chart
        width="100%"
        height="400px"
        chartType="SteppedAreaChart"
        loader={<div>Loading Chart</div>}
        data={chartData}
        options={{
          tooltip: {isHtml: true},
          chartArea: {
            left: 100,
            top: 40,
            width: '100%'
          },
          connectSteps: false,
          //areaOpacity: 0,
          colors: ['#5A5BE9'],
          hAxis: {
            title: 'Time (in seconds)',
            format: '#',
          },
          legend: 'none',
          vAxis: {
            title: 'Aggression level',
            ticks: [
              {v: -0.05, f: ''}, 
              {v: 0, f: 'Neutral'}, 
              {v: 0.5, f: 'Possibly aggressive'}, 
              {v: 1, f: 'Aggressive'},
            ],
          },
        }}
      />
      <ModeDropdown id="aggression" onSelectCallback={(key) => {setMode(key)}}/>
    </div>
  }

  export default AggressionChart;