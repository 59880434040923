import React, {useState} from 'react'
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {useCookies} from 'react-cookie'
import { GetText } from '../Lang'
import ReactGA from 'react-ga'

const GetCookiesBlock = () => {
  return (
    <>
        <b>{GetText('cookies', 'explanations', 'lastupdate')}</b><br/><br/>
        <p>{GetText('cookies', 'explanations', 'intro')}</p>
        
        <h4><b>{GetText('cookies', 'headers', 'whatiscookie')}</b></h4>
        <p>{GetText('cookies', 'explanations', 'whatiscookie')}</p>
        
        <h4><b>{GetText('cookies', 'headers', 'howweuse')}</b></h4>
        <p>{GetText('cookies', 'explanations', 'howweuse')}</p>

        <ul>
          <li dangerouslySetInnerHTML={{__html: GetText('cookies', 'explanations', 'howweusedetails', '1')}}></li>
          <li dangerouslySetInnerHTML={{__html: GetText('cookies', 'explanations', 'howweusedetails', '2')}}></li>
          <li dangerouslySetInnerHTML={{__html: GetText('cookies', 'explanations', 'howweusedetails', '3')}}></li>
        </ul>

        <h4><b>{GetText('cookies', 'headers', 'manage')}</b></h4>
        <p>{GetText('cookies', 'explanations', 'manage1')}</p>
        <ul>
          <li>
            <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en" target='_blank' rel="noopener noreferrer"><b>Google Chrome</b></a>
          </li>
          <li>
            <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target='_blank' rel="noopener noreferrer"><b>Firefox</b></a>
          </li>
          <li>
            <a href="https://www.cookiesandyou.com/disable-cookies/windows/edge/" target='_blank' rel="noopener noreferrer"><b>Edge</b></a>
          </li>
          <li>
            <a href="https://support.apple.com/en-am/guide/safari/sfri11471/mac" target='_blank' rel="noopener noreferrer"><b>Saferi</b></a>
          </li>
          <li>
            <a href="https://blogs.opera.com/news/2015/08/how-to-manage-cookies-in-opera/" target='_blank' rel="noopener noreferrer"><b>Opera</b></a>
          </li>
        </ul>
        <h4><b>{GetText('cookies', 'headers', 'updates')}</b></h4>
        <p>{GetText('cookies', 'explanations', 'updates')}</p>
    </>
  )
}

const CookieToast = () => {
  const [showModal, setShowModal] = useState(false)

  const onClickLearnMore = (event) => {
    event.preventDefault()
    ReactGA.modalview('cookie-policy')
    setShowModal(true)
  }

  const handleClose = (event) => {
    setShowModal(false)
  }

  const [cookie, setCookie] = useCookies(['cookie_consent'])
  return (!cookie['cookie_consent'] && <Alert variant="w3" className="m-0 row">
    <div className="container text-center">
      {GetText('generic', 'useOfCookies')}&nbsp;
      <a href="!#" onClick={onClickLearnMore}>Learn more</a>
      <button type="button" className="close ml-2 mb-1" onClick={() => setCookie('cookie_consent', 1, {path: '/'})}>
        <span aria-hidden="true">×</span>
        <span className="sr-only">Close</span>
      </button>
      <Modal 
        show={showModal} 
        dialogClassName="modal-70w"
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{GetText('cookies', 'h1')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{GetCookiesBlock()}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  </Alert>)
}

export default CookieToast;