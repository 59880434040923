import React, {useEffect} from 'react'
import { SetDocumentTitleAndMeta, Url, ApiDocsLink } from '../Utils'
import { GetText } from '../Lang'
import { Row, Col, Card, Button } from 'react-bootstrap'

import StandardContent from '../comp/StandardContent'
import Contact from '../comp/Contact'
import './UseCases.scss'

import SVGModeration from '../icons/cases/SVGModeration.svg'
import SVGDataClassification from '../icons/cases/SVGDataClassification.svg'
import SVGCommentAnalysis from '../icons/cases/SVGCommentAnalysis.svg'
import SVGReviewAnalysis from '../icons/cases/SVGReviewAnalysis.svg'
import SVGEmailClassification from '../icons/cases/SVGEmailClassification.svg'
import SVGBusinessCommunicationAnalysis from '../icons/cases/SVGBusinessCommunicationAnalysis.svg'
import SVGSpeechAnalysis from '../icons/cases/SVGSpeechAnalysis.svg'
import SVGInformationExtraction from '../icons/cases/SVGInformationExtraction.svg'

import SVGGovernment from '../icons/industries/SVGGovernment.svg'
import SVGHealthcare from '../icons/industries/SVGHealthcare.svg'
import SVGContentMarketing from '../icons/industries/SVGContentMarketing.svg'
import SVGLegalServices from '../icons/industries/SVGLegalServices.svg'
import SVGOthers from '../icons/industries/SVGOthers.svg'

import SVGUSDefense from '../icons/companies/SVGUSDefense.svg'
import SVGUSArmy from '../icons/companies/SVGUSArmy.svg'
import SVGAirForce from '../icons/companies/SVGAirForce.svg'
import SVGWSJ from '../icons/companies/SVGWSJ.svg'
import SVGUPI from '../icons/companies/SVGUPI.svg'

const UseCases = () => {
  useEffect(() => SetDocumentTitleAndMeta('useCases'));

  return (
    <div className="use-cases-page">
      <div className="use-cases-page-top-bg"/>
      <StandardContent className="container">
        <div className="use-cases-block">
          <h2>{GetText('useCases', 'header')}</h2>
          <Row>
            <Col sm={6}>
              <Card body>
                <img src={SVGModeration} height={60} alt={GetText('useCases', 'case1', 'title')}/>
                <p className="header">{GetText('useCases', 'case1', 'title')}</p>
                <p className="description">{GetText('useCases', 'case1', 'content')}</p>
              </Card>
            </Col>
            <Col sm={6}>
              <Card body>
                <img src={SVGDataClassification} height={60} alt={GetText('useCases', 'case2', 'title')}/>
                <p className="header">{GetText('useCases', 'case2', 'title')}</p>
                <p className="description">{GetText('useCases', 'case2', 'content')}</p>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <Card body>
                <img src={SVGCommentAnalysis} height={60} alt={GetText('useCases', 'case3', 'title')}/>
                <p className="header">{GetText('useCases', 'case3', 'title')}</p>
                <p className="description">{GetText('useCases', 'case3', 'content')}</p>
              </Card>
            </Col>
            <Col sm={6}>
              <Card body>
                <img src={SVGReviewAnalysis} height={60} alt={GetText('useCases', 'case4', 'title')}/>
                <p className="header">{GetText('useCases', 'case4', 'title')}</p>
                <p className="description">{GetText('useCases', 'case4', 'content')}</p>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <Card body>
                <img src={SVGEmailClassification} height={60} alt={GetText('useCases', 'case5', 'title')}/>
                <p className="header">{GetText('useCases', 'case5', 'title')}</p>
                <p className="description">{GetText('useCases', 'case5', 'content')}</p>
              </Card>
            </Col>
            <Col sm={6}>
              <Card body>
                <img src={SVGBusinessCommunicationAnalysis} height={60} alt={GetText('useCases', 'case6', 'title')}/>
                <p className="header">{GetText('useCases', 'case6', 'title')}</p>
                <p className="description">{GetText('useCases', 'case6', 'content')}</p>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <Card body>
                <img src={SVGSpeechAnalysis} height={60} alt={GetText('useCases', 'case7', 'title')}/>
                <p className="header">{GetText('useCases', 'case7', 'title')}</p>
                <p className="description">{GetText('useCases', 'case7', 'content')}</p>
              </Card>
            </Col>
            <Col sm={6}>
              <Card body>
                <img src={SVGInformationExtraction} height={60} alt={GetText('useCases', 'case8', 'title')}/>
                <p className="header">{GetText('useCases', 'case8', 'title')}</p>
                <p className="description">{GetText('useCases', 'case8', 'content')}</p>
              </Card>
            </Col>
          </Row>

          <Row className={"mt-5"}>
            <Col sm={4}></Col>
            <Col>
              <Button href={ApiDocsLink} block variant="primary" size='lg'>{GetText('generic', 'apiDocs')}</Button>
            </Col>
            <Col sm={4}></Col>
          </Row>
        </div>

        <div className="match-content-and-per-type-block">
          <div className="match-content-block">
            <h4>{GetText('useCases', 'case9', 'title')}</h4>
            <h5>{GetText('useCases', 'case9', 'header')}</h5>
            <Row>
              <Col sm={2}></Col>
              <Col sm={4}>
                <Card body>
                  <p className="header">Flesch Reading Ease Score</p>
                  <img width="40" alt="U.S. Department of Defense standard" src={SVGUSDefense}/>
                  <p className="description">U.S. Department of Defense standard</p>
                </Card>
              </Col>
              <Col sm={4}>
                <Card body>
                  <p className="header">Flesch-Kincaid Grade Level</p>
                  <img width="40" alt="U.S. Army standard" src={SVGUSArmy}/>
                  <p className="description">U.S. Army standard</p>
                </Card>
              </Col>
              <Col sm={2}></Col>
            </Row>

            <Row>
              <Col sm={2}></Col>
              <Col sm={4}>
                <Card body>
                    <p className="header">Linsear Write Formula</p>
                    <img width="40" alt="U.S. Air Force standard" src={SVGAirForce}/>
                    <p className="description">U.S. Air Force standard</p>
                </Card>
              </Col>
              <Col sm={4}>
                <Card body>
                    <p className="header">Gunning Fog Index</p>
                    <img width="40" alt="United Press International standard" src={SVGUPI}/>
                    <p className="description">United Press International standard</p><br/>
                    <img width="40" alt="The Wall Street Journal standard" src={SVGWSJ}/>
                    <p className="description">The Wall Street Journal standard</p>
                </Card>
              </Col>
              <Col sm={2}></Col>
            </Row>
          </div>

          <div className="per-type-block">
            <h5>Provide your customers tailored experience per text type</h5>
            <Row>
              <Col sm={6}>
                <img src="/images/usecases/guardian.png" width="100%" alt="Guardian"/>
              </Col>

              <Col sm={6}>
                <img src="/images/usecases/standford.png" width="100%" alt="Standford"/>
              </Col>
            </Row>
          </div>
        </div>

        <div className="industry-block">
          <h2>Thrive in any Industry!</h2>
          <Row>
            <Col>
              <Card body>
                <img src={SVGGovernment} height={80} alt={'Government'}/>
                <p className="header">Government</p>
              </Card>
            </Col>
            <Col>
              <Card body>
                <img src={SVGHealthcare} height={80} alt={'Healthcare'}/>
                <p className="header">Healthcare</p>
              </Card>
            </Col>
            <Col>
              <Card body>
                <img src={SVGContentMarketing} height={80} alt={'Content Marketing'}/>
                <p className="header">Content Marketing</p>
              </Card>
            </Col>
            <Col>
              <Card body>
                <img src={SVGLegalServices} height={80} alt={'Legal Services'}/>
                <p className="header">Legal Services</p>
              </Card>
            </Col>
            <Col>
              <Card body>
                <img src={SVGOthers} style={{'margin-top': '20px'}} height={80} alt={'And a lot more'}/>
              </Card>
            </Col>
          </Row>

          <Row className={"mt-4"}>
            <Col sm={4}></Col>
            <Col>
              <Button href={Url('Features')}
                variant="primary" 
                block
                size='lg'
                type="submit">Our Features</Button>
            </Col>
            <Col sm={4}></Col>
          </Row>
        </div>

        <Row>
          <Col md={2}></Col>
          <Col md={8} className="contact-form">
            <Contact/>
          </Col>
          <Col md={2}></Col>
        </Row>
      </StandardContent>
    </div>
  );
}

export default UseCases;