import LocalizedStrings from 'react-localization'
import enGeneric from './translations/en/generic.json'
import enMenu from './translations/en/menu.json'
import enCountries from './translations/en/countries.json'
import enContact from './translations/en/contact.json'
import enFeatures from './translations/en/features.json'
import enPageNotFound from './translations/en/pageNotFound.json'
import enThankYou from './translations/en/thankYou.json'
import enUseCases from './translations/en/useCases.json'
import enProduct from './translations/en/product.json'
import enCookies from './translations/en/cookies.json'

export const Lang = new LocalizedStrings({
    en: {
        generic: enGeneric,
        menu: enMenu,
        countries: enCountries,
        contact: enContact,
        pageNotFound: enPageNotFound,
        thankYou: enThankYou,
        features: enFeatures,
        useCases: enUseCases,
        product: enProduct,
        cookies: enCookies
    }
});

export const GetText = (key1, key2, key3, key4) => {
    if (key1 && key2 && key3 && key4) {
        if (Lang[key1] && Lang[key1][key2] && Lang[key1][key2][key3] && Lang[key1][key2][key3][key4]) {
            return Lang[key1][key2][key3][key4];
        }
        return key1 + '.' + key2 + '.' + key3 + '.' + key4;
    }
    if (key1 && key2 && key3) {
        if (Lang[key1] && Lang[key1][key2] && Lang[key1][key2][key3]) {
            return Lang[key1][key2][key3];
        }
        return key1 + '.' + key2 + '.' + key3;
    }
    if (key1 && key2) {
        if (Lang[key1] && Lang[key1][key2]) {
            return Lang[key1][key2];
        }
        return key1 + '.' + key2;
    }
    if (key1) {
        if (Lang[key1]) {
            return Lang[key1];
        }
        return key1;
    }
    return "";
}
