import React, {useEffect} from 'react'
import { Row, Col, Card, Button } from 'react-bootstrap'
import { SetDocumentTitleAndMeta, ApiDocsLink} from '../Utils'
import { GetText } from '../Lang'
import StandardContent from '../comp/StandardContent'
import Contact from '../comp/Contact'
import './Features.scss'

import SVGSentiment from '../icons/features/SVGSentiment.svg'
import SVGAggression from '../icons/features/SVGAggression.svg'
import SVGLangDetection from '../icons/features/SVGLangDetection.svg'
import SVGInsult from '../icons/features/SVGInsult.svg'
import SVGDiscrimination from '../icons/features/SVGDiscrimination.svg'
import SVGTopicDetection from '../icons/features/SVGTopicDetection.svg'
import SVGReadability from '../icons/features/SVGReadability.svg'

const Features = () => {
  useEffect(() => SetDocumentTitleAndMeta('features'));

  return (
    <div className="features-page">
      <div className="features-page-top-bg"/>
      <StandardContent className="container">

        <div className="features-block">
          <h2>{GetText('features', 'header')}</h2>
          <Row>
            <Col sm={4}>
              <Card body>
                <img src={SVGSentiment} height={100} alt={GetText('features', 'feature2', 'title')}/>
                <p className="header">{GetText('features', 'feature2', 'title')}</p>
                <p className="description">{GetText('features', 'feature2', 'content')}</p>
              </Card>
            </Col>
            <Col sm={4}>
              <Card body>
                <img src={SVGAggression} height={100} alt={GetText('features', 'feature3', 'title')}/>
                <p className="header">{GetText('features', 'feature3', 'title')}</p>
                <p className="description">{GetText('features', 'feature3', 'content')}</p>
              </Card>
            </Col>
            <Col sm={4}>
              <Card body>
                <img src={SVGLangDetection} height={100} alt={GetText('features', 'feature1', 'title')}/>
                <p className="header">{GetText('features', 'feature1', 'title')}</p>
                <p className="description">{GetText('features', 'feature1', 'content')}</p>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm={4}>
              <Card body>
                <img src={SVGInsult} height={100} alt={GetText('features', 'feature4', 'title')}/>
                <p className="header">{GetText('features', 'feature4', 'title')}</p>
                <p className="description">{GetText('features', 'feature4', 'content')}</p>
              </Card>
            </Col>
            <Col sm={4}>
              <Card body>
                <img src={SVGDiscrimination} height={100} alt={GetText('features', 'feature5', 'title')}/>
                <p className="header">{GetText('features', 'feature5', 'title')}</p>
                <p className="description">{GetText('features', 'feature5', 'content')}</p>
              </Card>
            </Col>
            <Col sm={4}>
              <Card body>
                <img src={SVGTopicDetection} height={100} alt={GetText('features', 'feature6', 'title')}/>
                <p className="header">{GetText('features', 'feature6', 'title')}</p>
                <p className="description">{GetText('features', 'feature6', 'content')}</p>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm={4}>
              <Card body>
                <img src={SVGReadability} height={100} alt={GetText('features', 'feature7', 'title')}/>
                <p className="header">{GetText('features', 'feature7', 'title')}</p>
                <p className="description">{GetText('features', 'feature7', 'content')}</p>
              </Card>
            </Col>
          </Row>

          <Row className={"mt-5"}>
            <Col sm={4}></Col>
            <Col>
              <Button href={ApiDocsLink} block variant="primary" size='lg'>{GetText('generic', 'apiDocs')}</Button>
            </Col>
            <Col sm={4}></Col>
          </Row>
        </div>

        <div className="data-presentation-block">
          <h2>Data Presentation Layers</h2>
          <Row>
            <Col sm={6}>
              <p className="header">Heat Maps</p>
              <img src="/images/features/heatmaps.png" width="100%" alt="Heat-maps"/>
            </Col>
            <Col sm={6}>
              <p className="header">Per-Sentence Analysis</p>
              <img src="/images/features/analysis.png" width="100%" alt="Per-Sentence Analysis"/>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <p className="header">Stats and Scores</p>
              <img src="/images/features/scores.png" width="100%" alt="Stats and Scores"/>
            </Col>
          </Row>
        </div>

        <Row>
          <Col md={2}></Col>
          <Col md={8} className="contact-form">
            <Contact/>
          </Col>
          <Col md={2}></Col>
        </Row>
      </StandardContent>
    </div>
  );
}

export default Features;