import React from 'react'
import {  Form } from 'react-bootstrap'

const ModeDropdown = (props) => {
    let callback = props.onSelectCallback || function () {};
    return (
      <div className="time-toggler">
        <Form.Check 
          inline
          defaultChecked
          label="Read time"
          onClick={(event) => callback('read')}
          name={"time" + props.id}
          value="read"
          type={"radio"}
          id={"read-time-" + props.id} />

        <Form.Check 
          inline
          label="Speak time"
          onClick={(event) => callback('speak')}
          name={"time" + props.id}
          value="speak"
          type={"radio"}
          id={"speak-time-" + props.id} />
      </div>
    )
  }

  export default ModeDropdown;