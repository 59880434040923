import React, {useState, useEffect} from 'react'
import { SendHttpRequest, FormatString, Url, SetDocumentTitleAndMeta, ApiDocsLink } from '../Utils'
import { Row, Col, Tab, Nav, Card, Button, Form } from 'react-bootstrap'
import StandardContent from '../comp/StandardContent'
import Error from '../comp/Error'
import { GetText } from '../Lang'
import {secondsToFormattedString, SMOGGradeToLabel, LinsearWriteToLabel, FleschKincaidToLabel, AutomatedReadabilityIndexToLabel, GunningFogToLabel, DaleChallToLabel, ColemanLiauToLabel, FleschReadingEaseToLabel} from '../Utils'

import ReactGA from 'react-ga'
import Footer from '../comp/Footer'

import AggressionChart from '../comp/charts/AggressionChart'
import SentimentChart from '../comp/charts/SentimentChart'
import ReadabilityChart from '../comp/charts/ReadabilityChart'
import DiscriminationChart from '../comp/charts/DiscriminationChart'
import ToxicChart from '../comp/charts/ToxicChart'

import AggressionHeatMap from '../comp/heatmaps/AggressionHeatMap'
import SentimentHeatMap from '../comp/heatmaps/SentimentHeatMap'
import ReadabilityHeatMap from '../comp/heatmaps/ReadabilityHeatMap'
import DiscriminationHeatMap from '../comp/heatmaps/DiscriminationHeatMap'
import ToxicHeatMap from '../comp/heatmaps/ToxicHeatMap'
import Contact from '../comp/Contact'
import './Product.scss'

import SVGUSDefense from '../icons/companies/SVGUSDefense.svg'
import SVGUSArmy from '../icons/companies/SVGUSArmy.svg'
import SVGAirForce from '../icons/companies/SVGAirForce.svg'
import SVGUPI from '../icons/companies/SVGUPI.svg'

import { ChevronDown, ChevronUp, X } from 'react-bootstrap-icons'

import ISO6391 from 'iso-639-1'

const SentimentInfoBlock = () => {
  return <p>{GetText('features', 'feature2', 'content')}</p>
}

const AggressionInfoBlock = () => {
  return <p>{GetText('features', 'feature3', 'content')}</p>
}

const ReadabilityInfoBlock = () => {
  return <p>{GetText('features', 'feature7', 'content')}</p>
}

const DiscriminationInfoBlock = () => {
  return <p>{GetText('features', 'feature5', 'content')}</p>
}

const ToxicInfoBlock = () => {
  return <p>{GetText('features', 'feature4', 'content')}</p>
}

const GeneralStatsRow = (data) => {
  return (
    <section className="general-stats-section">
      <h2>{`General Statistics`}</h2>
      <Row>
        <Col>
        {
          data.summary && data.summary.language &&
          <Card body>
            <span className="name">Text language</span>
            <span className="value">{ISO6391.getName(data.summary.language)}</span>
          </Card>
        }
        {
          data.summary &&
          <>
            <Card body>
              <span className="name">Characters total</span>
              <span className="value">{data.summary.charCount}</span>
            </Card>
            
            <Card body>
              <span className="name">Words total</span>
              <span className="value">{data.summary.wordCount}</span>
            </Card>

            <Card body>
              <span className="name">Sentences total</span>
              <span className="value">{data.summary.sentCount}</span>
            </Card>
          </>
        }
        </Col>


        <Col>
        {
          data.readTime &&
          <Card body>
            <span className="name">Text read time</span>
            <span className="value">{secondsToFormattedString(Math.round(data.readTime.total))}</span>
          </Card>
        }
        {
          data.readTime.breakdown && 
          <Card body>
            <span className="name">Shortest sentence read time</span>
            <span className="value">{secondsToFormattedString(Math.round(Math.min(...data.readTime.breakdown)))}</span>
          </Card>
        }
        {
          data.readTime.breakdown && 
          <Card body>
            <span className="name">Longest sentence read time</span>
            <span className="value">{secondsToFormattedString(Math.round(Math.max(...data.readTime.breakdown)))}</span>
          </Card>
        }
        </Col>


        <Col>
        {
          data.speakTime &&
          <Card body>
            <span className="name">Text speak time</span>
            <span className="value">{secondsToFormattedString(Math.round(data.speakTime.total))}</span>
          </Card>
        }
        {
          data.speakTime.breakdown && 
          <Card body>
            <span className="name">Shortest sentence speak time</span>
            <span className="value">{secondsToFormattedString(Math.round(Math.min(...data.speakTime.breakdown)))}</span>
          </Card>
        }
        {
          data.speakTime.breakdown && 
          <Card body>
            <span className="name">Longest sentence speak time</span>
            <span className="value">{secondsToFormattedString(Math.round(Math.max(...data.speakTime.breakdown)))}</span>
          </Card>
        }
        </Col>
      </Row>
    </section>
  )
}

const Product = () => {
  const [headerHeight, setHeaderHeight] = useState(570)

  useEffect(() => {
    SetDocumentTitleAndMeta('product')
  });

  useEffect(() => {
    var elems = document.getElementsByClassName('intro-section')
    if (elems.length === 1) {
      setHeaderHeight(elems[0].offsetHeight + elems[0].offsetTop + 100)
    }
  }, [])

  const [text, setText] = useState(GetText('product', 'demotext1'));
  
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // dirty workaround of google chart sizing problem
  const [drawSentimentChart, setDrawSentimentChart] = useState(0);
  const [drawReadabilityChart, setDrawReadabilityChart] = useState(0);
  const [drawAggressionChart, setDrawAggressionChart] = useState(0);
  const [drawDiscriminationChart, setDrawDiscriminationChart] = useState(0);
  const [drawToxicChart, setDrawToxicChart] = useState(0);

  const [showSentimentInfoBlock, setShowSentimentInfoBlock] = useState(false)
  const [showReadabilityInfoBlock, setShowReadabilityInfoBlock] = useState(false)
  const [showAggressionInfoBlock, setShowAggressionInfoBlock] = useState(false)
  const [showDiscriminationInfoBlock, setShowDiscriminationInfoBlock] = useState(false)
  const [showToxicInfoBlock, setShowToxicInfoBlock] = useState(false)

  const [headerStates, setHeaderStates] = useState({})
  const [headerExtendableStates, setHeaderExtendableStates] = useState({})

  useEffect(() => {
    // Update the document title using the browser API
    let topicRows = document.getElementsByClassName('topic-card')
    let object = {}
    for (let i=0; i < topicRows.length; i++) {
      object[i] = topicRows[i].scrollHeight > topicRows[i].offsetHeight;
    }
    setHeaderExtendableStates(object)
  }, [data])

  const DetermineActiveTab = (data) => {
    if (!data) return ''
    if ('sentiment' in data) {
      return 'sentiment'
    }
    if ('readability' in data) {
      return 'readability'
    }
    if ('aggression' in data) {
      return 'aggression'
    }
    if ('discrimination' in data) {
      return 'discrimination'
    }
    if ('toxic' in data) {
      return 'toxic'
    }
  }

  const defaultSubTab = 'heatmap';

  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (event, text) => {
    event.preventDefault();

    if (text.length < 200) {
      setErrorMessage('Enter at least 200 characters')
      event.stopPropagation();
      return
    }

    if (text.length > 2000) {
      setErrorMessage('Maximum number of allowed characters is 2000')
      event.stopPropagation();
      return
    }

    ReactGA.event({
      category: 'Analysis',
      action: 'Triggered Analysis'
    })
    setErrorMessage('')
    setIsLoading(true);
    SendHttpRequest('AnalyseText', { 
        method: "POST",
        body: JSON.stringify({
          text: text
        })
      }, 60 * 1000 // 60 seconds
    ).then(function(response) {
        setIsLoading(false);
        if (response.data) {
          setData(response.data)
          setTimeout(() => {
            const generalStatsSection = document.getElementsByClassName('general-stats-section')
            if (generalStatsSection.length === 1) {
              window.scrollTo(0, generalStatsSection[0].offsetTop - 80)
            }
          }, 300)
        } else {
          setError(true);
        }
    }).catch(() => {
      setIsLoading(false)
      setError(true)
    });
  }

  const [messageLength, setMessageLength] = useState(text.length);

  const textAreaOnInput = (event) => {
    setMessageLength(event.target.value.length)
  }

  return (
    <div className="product-page">
      <div className="product-page-top-bg" style={{'height': headerHeight + 'px'}}>
        <div className="what"></div>
      </div>
      <StandardContent>
        {
          error ? <Error/> :
          <>
            <div className="intro-section">
              <h1>{GetText('product', 'headers', 'product')}</h1>
              <p className="lead" dangerouslySetInnerHTML={{__html: FormatString(GetText('product', 'intro'), ['<a href="' + Url('Features') + '">', '</a>', '<a href="' + Url('UseCases') + '">', '</a>'])}}></p>
            </div>
            <Row>
              <Col>
                <Form className="text-analysis-form" onSubmit={(event) => handleSubmit(event, text, setData, setError, setIsLoading)}>

                  <Form.Group controlId="formGridText">
                      <Form.Control 
                        as="textarea" 
                        rows="6" 
                        placeholder={`Insert a chunk of text for analysis`} 
                        value={text} 
                        onChange={(event) => {setText(event.target.value)}}
                        onInput={textAreaOnInput}
                      />
                      {
                        errorMessage &&
                      <div className="invalid">{errorMessage}</div>
                      }
                  </Form.Group>

                  <span className="note">Text for analysis 200-2000 characters – currently {messageLength} character(s)</span>
      
                  <Button variant="primary" 
                    className="block-md float-right" 
                    disabled={isLoading}
                    type="submit">
                      {isLoading ? 'loading..' : 'Analyze Now'}
                  </Button>
                </Form>
              </Col>
            </Row>
            
            {
              data && 
              <GeneralStatsRow {...data}/>
            }

            {
              data && 
              <section className="detected-scores-section">
                <Tab.Container defaultActiveKey={DetermineActiveTab(data)}  onSelect={(key) => {
                  switch (key) {
                    case 'sentiment':
                      if (drawSentimentChart) {
                        setDrawSentimentChart(drawSentimentChart + 1);
                      }
                      break;
                    case 'readability':
                      if (drawReadabilityChart) {
                        setDrawReadabilityChart(drawReadabilityChart + 1);
                      }
                      break;
                    case 'aggression':
                      if (drawAggressionChart) {
                        setDrawAggressionChart(drawAggressionChart + 1);
                      }
                      break;
                    case 'discrimination':
                      if (drawDiscriminationChart) {
                        setDrawDiscriminationChart(drawDiscriminationChart + 1);
                      }
                      break;
                    case 'toxic':
                      if (drawToxicChart) {
                        setDrawToxicChart(drawToxicChart + 1);
                      }
                      break;
                    default:
                      break;
                  }
                  }}>
                  
                  <h2>{`Metric Scores`}</h2>
                  <Row>
                    <Col sm={2}>
                      <Nav variant="left-tab" className="flex-column" onSelect={(key) => {
                        ReactGA.event({
                          category: 'Analysis',
                          action: 'Clicked on Metric Score',
                          label: key
                        })
                      }}>
                        {
                          data.sentiment &&
                          <Nav.Item>
                            <Nav.Link eventKey="sentiment">Sentiment</Nav.Link>
                          </Nav.Item>
                        }
                        {
                          data.readability &&
                          <Nav.Item>
                            <Nav.Link eventKey="readability">Readability</Nav.Link>
                          </Nav.Item>
                        }
                        {
                          data.aggression &&
                          <Nav.Item>
                            <Nav.Link eventKey="aggression">Aggression</Nav.Link>
                          </Nav.Item>
                        }
                        {
                          data.toxic &&
                          <Nav.Item>
                            <Nav.Link eventKey="discrimination">Discrimination</Nav.Link>
                          </Nav.Item>
                        }
                        {
                          data.toxic &&
                          <Nav.Item>
                            <Nav.Link eventKey="toxic">Insult</Nav.Link>
                          </Nav.Item>
                        }
                      </Nav>
                    </Col>

                    <Col sm={10}>
                      <Tab.Content>
                        <Tab.Pane eventKey="sentiment">
                        {
                          data.sentiment &&
                          <Tab.Container defaultActiveKey={defaultSubTab} onSelect={(key) => {
                            ReactGA.event({
                              category: 'Analysis',
                              action: 'Changed View Mode',
                              label: key
                            })
                            if (key === 'chart') setDrawSentimentChart(drawSentimentChart + 1); }
                          }>
                            <Nav variant="top-tab">
                              <Nav.Item>
                                <Nav.Link eventKey="heatmap">Heatmap</Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="mr-auto">
                                <Nav.Link eventKey="chart">Chart</Nav.Link>
                              </Nav.Item>

                              <Button 
                                className="show-info"
                                variant={'link'}
                                onClick={() => {
                                  ReactGA.event({
                                    category: 'Analysis',
                                    action: 'Clicked on Learn More',
                                    label: 'sentiment'
                                  })
                                  setShowSentimentInfoBlock(!showSentimentInfoBlock) 
                                }}>
                                  More about Sentiment{showSentimentInfoBlock ? <X color={'#5A5BE9'} size={22}/> : <ChevronDown color={'#5A5BE9'} size={16}/> } 
                              </Button>
                            </Nav>
                            <Tab.Content className="w-100">
                              <Tab.Pane eventKey="heatmap">
                                {
                                  showSentimentInfoBlock ?
                                  <SentimentInfoBlock/>
                                  :
                                  <>
                                    <SentimentHeatMap {...data}/>
                                    <div className="legends">
                                      <div><span className="color sentiment level-0"></span><span className="text">Negative: {(data.sentiment.score['negative'] * 100).toFixed(2)}%</span></div>
                                      <div><span className="color sentiment level-1"></span><span className="text">Neutral: {(data.sentiment.score['neutral'] * 100).toFixed(2)}%</span></div>
                                      <div><span className="color sentiment level-2"></span><span className="text">Positive: {(data.sentiment.score['positive'] * 100).toFixed(2)}%</span></div>
                                    </div>
                                  </>
                                }
                              </Tab.Pane>
                              <Tab.Pane eventKey="chart" className="chart-tab h-100">
                                {
                                  showSentimentInfoBlock ?
                                  <SentimentInfoBlock/>
                                  :
                                  (
                                  drawSentimentChart > 0 && 
                                  <>
                                    <SentimentChart data={data}/>
                                    <div className="legends mt-2">
                                      <div><span className="color sentiment level-0"></span><span className="text">Negative: {(data.sentiment.score['negative'] * 100).toFixed(2)}%</span></div>
                                      <div><span className="color sentiment level-1"></span><span className="text">Neutral: {(data.sentiment.score['neutral'] * 100).toFixed(2)}%</span></div>
                                      <div><span className="color sentiment level-2"></span><span className="text">Positive: {(data.sentiment.score['positive'] * 100).toFixed(2)}%</span></div>
                                    </div>
                                  </>
                                  )
                                }
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        }
                        </Tab.Pane>

                        <Tab.Pane eventKey="readability">
                        {
                          data.readability &&
                          <Tab.Container defaultActiveKey={defaultSubTab} onSelect={(key) => {
                            ReactGA.event({
                              category: 'Analysis',
                              action: 'Changed View Mode',
                              label: key
                            })
                            if (key === 'chart') setDrawReadabilityChart(drawReadabilityChart + 1); }
                          }>
                            <Nav variant="top-tab">
                              <Nav.Item>
                                <Nav.Link eventKey="heatmap">Heatmap</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="chart">Chart</Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="mr-auto">
                                <Nav.Link eventKey="data">Data</Nav.Link>
                              </Nav.Item>

                              <Button 
                                className="show-info"
                                variant={'link'}
                                onClick={() => {
                                  ReactGA.event({
                                    category: 'Analysis',
                                    action: 'Clicked on Learn More',
                                    label: 'readability'
                                  })
                                  setShowReadabilityInfoBlock(!showReadabilityInfoBlock) }
                                }>
                                  More about Readability{showReadabilityInfoBlock ? <X color={'#5A5BE9'} size={22}/> : <ChevronDown color={'#5A5BE9'} size={16}/> } 
                              </Button>
                            </Nav>

                            <Tab.Content className="w-100">
                              <Tab.Pane eventKey="heatmap">
                                  {
                                    showReadabilityInfoBlock ? 
                                    <ReadabilityInfoBlock/>
                                    :
                                    <>
                                      <ReadabilityHeatMap {...data}/>
                                      <div className="legends">
                                        <div><span className="color readability level-1"></span><span className="text">Average</span></div>
                                        <div><span className="color readability level-2"></span><span className="text">Very easy</span></div>
                                        <div><span className="color readability level-0"></span><span className="text">Very difficult</span></div>
                                      </div>
                                    </>
                                  }
                              </Tab.Pane>
                              <Tab.Pane eventKey="chart" className="chart-tab">
                                {
                                  showReadabilityInfoBlock ?
                                  <ReadabilityInfoBlock/>
                                  :
                                  (
                                    drawReadabilityChart > 0 && 
                                    <ReadabilityChart data={data}/>
                                  )
                                }
                              </Tab.Pane>
                              <Tab.Pane eventKey="data" className="readability-scores">
                                {
                                  showReadabilityInfoBlock ?
                                  <ReadabilityInfoBlock/>
                                  :
                                  (
                                  data.readability && data.readability.scores &&
                                  <>
                                    {
                                      data.readability.scores.fleschReadingEase &&
                                      <Card body>
                                        <div className="usage-note">
                                          <img src={SVGUSDefense} alt="Used by U.S. Department of Defense"/>
                                          <div style={{'marginTop': '4px'}}>
                                            <span>Used by</span>
                                            <span>U.S. Department of Defense</span>
                                          </div>
                                        </div>
                                        <span className="name">Flesch reading ease</span>
                                        <span className="value">{data.readability.scores.fleschReadingEase.toFixed(1)} ({FleschReadingEaseToLabel(data.readability.scores.fleschReadingEase)})</span>
                                      </Card>
                                    }
                                    {
                                      data.readability.scores.gunningFog &&
                                      <Card body>
                                        <div className="usage-note">
                                          <img src={SVGUPI} alt="Used by United Press International"/>
                                          <div style={{'marginTop': '4px'}}>
                                            <span>Used by</span>
                                            <span>United Press International</span>
                                          </div>
                                        </div>
                                        <span className="name">Gunning fog index</span>
                                        <span className="value">{data.readability.scores.gunningFog.toFixed(1)} ({GunningFogToLabel(data.readability.scores.gunningFog)})</span>
                                      </Card>
                                    }
                                    {
                                      data.readability.scores.smogGrade &&
                                      <Card body>
                                        <span className="name">SMOG</span>
                                        <span className="value">{data.readability.scores.smogGrade.toFixed(1)} ({SMOGGradeToLabel(data.readability.scores.smogGrade)})</span>
                                      </Card>
                                    }
                                    {
                                      data.readability.scores.fleschKincaidGrade &&
                                      <Card body>
                                        <div className="usage-note">
                                          <img src={SVGUSArmy} alt="Used by U.S. Army"/>
                                          <div style={{'marginTop': '4px'}}>
                                            <span>Used by</span>
                                            <span>U.S. Army</span>
                                          </div>
                                        </div>
                                        <span className="name">Flesch-Kincaid grade</span>
                                        <span className="value">{data.readability.scores.fleschKincaidGrade.toFixed(1)} ({FleschKincaidToLabel(data.readability.scores.fleschKincaidGrade)})</span>
                                      </Card>
                                    }
                                    {
                                      data.readability.scores.automatedIndex &&
                                      <Card body>
                                        <span className="name">Automated readability index</span>
                                        <span className="value">{data.readability.scores.automatedIndex.toFixed(1)} ({AutomatedReadabilityIndexToLabel(data.readability.scores.automatedIndex)})</span>
                                      </Card>
                                    }
                                    {
                                      data.readability.scores.daleChall &&
                                      <Card body>
                                        <span className="name">Dale-Chall</span>
                                        <span className="value">{data.readability.scores.daleChall.toFixed(1)} ({DaleChallToLabel(data.readability.scores.daleChall)})</span>
                                      </Card>
                                    }
                                    {
                                      data.readability.scores.linsearWrite &&
                                      <Card body>
                                        <div className="usage-note">
                                          <img src={SVGAirForce} alt="Used by U.S. Air Force"/>
                                          <div style={{'marginTop': '4px'}}>
                                            <span>Used by</span>
                                            <span>U.S. Air Force</span>
                                          </div>
                                        </div>
                                        <span className="name">Linsear write</span>
                                        <span className="value">{data.readability.scores.linsearWrite.toFixed(1)} ({LinsearWriteToLabel(data.readability.scores.linsearWrite)})</span>
                                      </Card>
                                    }
                                    {
                                      data.readability.scores.colemanLiau &&
                                      <Card body>
                                        <span className="name">Coleman-Liau Index</span>
                                        <span className="value">{data.readability.scores.colemanLiau.toFixed(1)} ({ColemanLiauToLabel(data.readability.scores.colemanLiau)})</span>
                                      </Card>
                                    }
                                  </>
                                  )
                                }
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        }
                        </Tab.Pane>

                        <Tab.Pane eventKey="aggression">
                        {
                          data.aggression &&
                          <Tab.Container defaultActiveKey={defaultSubTab} onSelect={(key) => {
                            ReactGA.event({
                              category: 'Analysis',
                              action: 'Changed View Mode',
                              label: key
                            })
                            if (key === 'chart') setDrawAggressionChart(drawAggressionChart + 1); }
                          }>
                            <Nav variant="top-tab">
                              <Nav.Item>
                                <Nav.Link eventKey="heatmap">Heatmap</Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="mr-auto">
                                <Nav.Link eventKey="chart">Chart</Nav.Link>
                              </Nav.Item>

                              <Button 
                                className="show-info"
                                variant={'link'}
                                onClick={() => {
                                  ReactGA.event({
                                    category: 'Analysis',
                                    action: 'Clicked on Learn More',
                                    label: 'aggression'
                                  })
                                  setShowAggressionInfoBlock(!showAggressionInfoBlock) }
                                }>
                                  More about Aggression{showAggressionInfoBlock ? <X color={'#5A5BE9'} size={22}/> : <ChevronDown color={'#5A5BE9'} size={16}/> } 
                              </Button>
                            </Nav>

                            <Tab.Content className="w-100">
                              <Tab.Pane eventKey="heatmap">
                                  {
                                    showAggressionInfoBlock ?
                                    <AggressionInfoBlock/>
                                    :
                                    <>
                                      <AggressionHeatMap {...data}/>
                                      <div className="legends">
                                        <div><span className="color aggression level-0"></span><span className="text">Neutral: {(data.aggression.score['neutral'] * 100).toFixed(2)}%</span></div>
                                        <div><span className="color aggression level-1"></span><span className="text">Aggressive: {(data.aggression.score['aggression'] * 100).toFixed(2)}%</span></div>
                                      </div>
                                    </>
                                  }
                              </Tab.Pane>
                              <Tab.Pane eventKey="chart" className="chart-tab">
                                {
                                  showAggressionInfoBlock ?
                                  <AggressionInfoBlock/>
                                  :
                                  (
                                    drawAggressionChart > 0 &&
                                    <>
                                      <AggressionChart data={data}/>
                                      <div className="legends mt-2">
                                        <div><span className="color aggression level-0"></span><span className="text">Neutral: {(data.aggression.score['neutral'] * 100).toFixed(2)}%</span></div>
                                        <div><span className="color aggression level-1"></span><span className="text">Aggressive: {(data.aggression.score['aggression'] * 100).toFixed(2)}%</span></div>
                                      </div>
                                    </>
                                  )
                                }
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        }
                        </Tab.Pane>

                        <Tab.Pane eventKey="discrimination">
                        {
                          data.toxic && data.toxic.score && data.toxic.score.discrimination &&
                          <Tab.Container defaultActiveKey={defaultSubTab} onSelect={(key) => {
                            ReactGA.event({
                              category: 'Analysis',
                              action: 'Changed View Mode',
                              label: key
                            })
                            if (key === 'chart') setDrawDiscriminationChart(drawDiscriminationChart + 1); }
                          }>
                            <Nav variant="top-tab">
                              <Nav.Item>
                                <Nav.Link eventKey="heatmap">Heatmap</Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="mr-auto">
                                <Nav.Link eventKey="chart">Chart</Nav.Link>
                              </Nav.Item>

                              <Button 
                                className="show-info"
                                variant={'link'}
                                onClick={() => {
                                  ReactGA.event({
                                    category: 'Analysis',
                                    action: 'Clicked on Learn More',
                                    label: 'discrimination'
                                  })
                                  setShowDiscriminationInfoBlock(!showDiscriminationInfoBlock) }
                                }>
                                  More about Discrimination{showDiscriminationInfoBlock ? <X color={'#5A5BE9'} size={22}/> : <ChevronDown color={'#5A5BE9'} size={16}/> } 
                              </Button>
                            </Nav>
                            
                            <Tab.Content className="w-100">
                              <Tab.Pane eventKey="heatmap">
                                {
                                  showDiscriminationInfoBlock ?
                                  <DiscriminationInfoBlock/>
                                  :
                                  <>
                                    <DiscriminationHeatMap {...data}/>
                                    <div className="legends">
                                      <div><span className="color discrimination level-0"></span><span className="text">Neutral: {((1.0 - data.toxic.score.discrimination) * 100).toFixed(2)}%</span></div>
                                      <div><span className="color discrimination level-1"></span><span className="text">Discrimination: {(data.toxic.score.discrimination * 100).toFixed(2)}%</span></div>
                                    </div>
                                  </>
                                }
                              </Tab.Pane>
                              <Tab.Pane eventKey="chart" className="chart-tab">
                                {
                                  showDiscriminationInfoBlock?
                                  <DiscriminationInfoBlock/>
                                  :
                                  (
                                    drawDiscriminationChart > 0 &&
                                    <>
                                      <DiscriminationChart data={data}/>
                                      <div className="legends mt-2">
                                        <div><span className="color discrimination level-0"></span><span className="text">Neutral: {((1.0 - data.toxic.score.discrimination) * 100).toFixed(2)}%</span></div>
                                        <div><span className="color discrimination level-1"></span><span className="text">Discrimination: {(data.toxic.score.discrimination * 100).toFixed(2)}%</span></div>
                                      </div>
                                    </>
                                  )
                                }
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        }
                        </Tab.Pane>

                        <Tab.Pane eventKey="toxic">
                        {
                          data.toxic && data.toxic.score && data.toxic.score.toxic &&
                          <Tab.Container defaultActiveKey={defaultSubTab}  onSelect={(key) => {
                            ReactGA.event({
                              category: 'Analysis',
                              action: 'Changed View Mode',
                              label: key
                            })
                            if (key === 'chart') setDrawToxicChart(drawToxicChart + 1); }
                          }>
                            <Nav variant="top-tab">
                              <Nav.Item>
                                <Nav.Link eventKey="heatmap">Heatmap</Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="mr-auto">
                                <Nav.Link eventKey="chart">Chart</Nav.Link>
                              </Nav.Item>

                              <Button 
                                className="show-info"
                                variant={'link'}
                                onClick={() => {
                                  ReactGA.event({
                                    category: 'Analysis',
                                    action: 'Clicked on Learn More',
                                    label: 'insult'
                                  })
                                  setShowToxicInfoBlock(!showToxicInfoBlock) }
                                }>
                                  More about Insult{showToxicInfoBlock ? <X color={'#5A5BE9'} size={22}/> : <ChevronDown color={'#5A5BE9'} size={16}/> } 
                              </Button>
                            </Nav>
                            
                            <Tab.Content className="w-100">
                              <Tab.Pane eventKey="heatmap">
                                  {
                                    showToxicInfoBlock ?
                                    <ToxicInfoBlock/>
                                    :
                                    <>
                                      <ToxicHeatMap {...data}/>
                                      <div className="legends">
                                        <div><span className="color insult level-0"></span><span className="text">Neutral: {((1.0 - data.toxic.score.toxic) * 100).toFixed(2)}%</span></div>
                                        <div><span className="color insult level-1"></span><span className="text">Insult: {(data.toxic.score.toxic * 100).toFixed(2)}%</span></div>
                                      </div>
                                    </>
                                  }
                              </Tab.Pane>
                              <Tab.Pane eventKey="chart" className="chart-tab">
                                {
                                  showToxicInfoBlock ?
                                  <ToxicInfoBlock/>
                                  :
                                  (
                                    drawToxicChart > 0 &&
                                    <>
                                      <ToxicChart data={data}/>
                                      <div className="legends mt-2">
                                        <div><span className="color insult level-0"></span><span className="text">Neutral: {((1.0 - data.toxic.score.toxic) * 100).toFixed(2)}%</span></div>
                                        <div><span className="color insult level-1"></span><span className="text">Insult: {(data.toxic.score.toxic * 100).toFixed(2)}%</span></div>
                                      </div>
                                    </>
                                  )
                                }
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        }
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </section>
            }
            
            {
              data && data.topics &&
              <section className="advanced-data-section">
              <h2>{`Advanced Data`}</h2>
              <div>
                {
                  data.topics.map((topics, index) => {
                    const cardClasses = ['topic-card']
                    if (index in headerStates && headerStates[index]) {
                      cardClasses.push('open')
                    } else {
                      cardClasses.push('closed')
                    }
                    if (index in headerExtendableStates && headerExtendableStates[index]) {
                      cardClasses.push('extendable')
                    }
                    
                    return (
                      <Card 
                        key={index} 
                        onClick={(e) => {
                          const clone = {...headerStates}
                          if (index in clone) {
                            clone[index] = !clone[index]
                          } else {
                            clone[index] = true
                          }
                          setHeaderStates(clone)
                        }}
                        className={ cardClasses.join(' ') }
                      >
                          <div className="topic-name">
                            <span>Topic {index + 1}:</span>
                          </div>
                          <div className="topic-list">
                          {
                            topics.map((topic, index2) => {
                                return <span className="topic-tag" key={index2}>{topic.phrase} - {(topic.relevance * 100).toFixed(2)}%</span>
                              }
                            )
                          }
                          </div>

                          {
                            index in headerExtendableStates && headerExtendableStates[index] &&
                            (
                              index in headerStates && headerStates[index] ?
                              <ChevronUp color={'#5A5BE9'} size={22}/>
                              :
                              <ChevronDown color={'#5A5BE9'} size={22}/>
                            )
                          }
                      </Card>
                      )
                  }
                  )
                }
              </div>
            </section>
            }
            {
              data && 
              <Row className={"mt-5"}>
                <Col sm={4}></Col>
                <Col>
                  <Button href={ApiDocsLink} block variant="primary" size='lg'>{GetText('generic', 'apiDocs')}</Button>
                </Col>
                <Col sm={4}></Col>
              </Row>
            }
          </>
        }
        
        {
          data &&
          <Row>
            <Col md={2}></Col>
            <Col md={8} className="contact-form">
              <Contact/>
            </Col>
            <Col md={2}></Col>
          </Row>
        }
      </StandardContent>

      <Footer hideFooter={!data}/>
    </div>
  );
}

export default Product;