import React, { useState } from 'react'
import Chart from "react-google-charts"
import {normFleschReadingEase} from '../../Utils'
import ModeDropdown from './ModeDropdown'

function fleschReadingEaseToLabel(value) {
  if (value === 0) {
    return 'Very easy';
  }
  if (value === 1) {
    return 'Easy';
  } 
  if (value === 2) {
    return 'Average';
  }
  if (value === 3) {
    return 'Difficult';
  }
  if (value === 4) {
    return 'Very difficult'
  }
}

const ReadabilityChart = (props) => {
    const [mode, setMode] = useState('read');

    const durations = props.data[mode + 'Time'].breakdown;
    let chartData = []
    let tmp = 0;
    for (let i=0; i < props.data.readability.breakdown.length; i++) {
      tmp += durations[i]
      let value = normFleschReadingEase(props.data.readability.breakdown[i])
      let label = fleschReadingEaseToLabel(value)
      chartData.push([tmp, value, props.data.tokens[i] + ' [' + label + ']'])
    }
    
    chartData.unshift([mode + " time", "Difficulty", {'role': 'tooltip'}])
    return <div style={{'height': '400px'}}>
      <Chart
        width="100%"
        height="400px"
        chartType="SteppedAreaChart"
        loader={<div>Loading Chart</div>}
        data={chartData}
        options={{
          tooltip: {isHtml: true},
          chartArea: {
            left: 100,
            top: 40,
            width: '100%',
          },
          connectSteps: false,
          //areaOpacity: 0,
          colors: ['#5A5BE9'],
          hAxis: {
            title: 'Time (in seconds)',
            format: '#',
          },
          legend: 'none',
          vAxis: {
            title: 'Readability',
            ticks: [
              {v: 4, f: 'Very difficult'}, 
              {v: 3, f: 'Difficult'}, 
              {v: 2, f: 'Average'}, 
              {v: 1, f: 'Easy'}, 
              {v: 0, f: 'Very easy'}, 
              {v: -0.2, f: ''}, 
            ],
          },
        }}
      />
      <ModeDropdown id="readability" onSelectCallback={(key) => {setMode(key)}}/>
    </div>
  }

  export default ReadabilityChart;