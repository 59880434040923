import React, {useEffect} from 'react'
import { SetDocumentTitleAndMeta } from '../Utils';
import { Row, Col } from 'react-bootstrap'
import StandardContent from '../comp/StandardContent'
import './Contact.scss'

import ContactForm from '../comp/Contact'
  
const Contact = () => {
  useEffect(() => SetDocumentTitleAndMeta('contact'));

  return (
    <div className="contact-page">
      <div className="contact-page-top-bg"/>
        <StandardContent>
          <Row>
            <Col md={2}></Col>
            <Col md={8} className="contact-form">
              <ContactForm/>
            </Col>
            <Col md={2}></Col>
          </Row>
        </StandardContent>
    </div>
  );
}

export default Contact;