import React from 'react'

const ToxicHeatMap = (data) => {
    let breakdown = data.toxic['breakdown'];
    return (
        <p>
        {
            data.tokens.map((item, index) => {
                let level = 0;
                let currentVal = breakdown[index]['toxic'];

                if (currentVal > 0.8) {
                    level = 2;
                } else if (currentVal > 0.4) {
                    level = 1;
                }

                if (index === 0) {
                    return <span className={`toxic-heatmap sent level-`+ level} key={index}>{item}</span>
                } else {
                    return <span key={index}>&nbsp;<span className={`toxic-heatmap sent level-`+ level} key={index}>{item}</span></span>
                }
            }
            )
        }
        </p>
    )
}

export default ToxicHeatMap;