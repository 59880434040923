
import React, { useEffect } from 'react'
import ReactGA from 'react-ga'

const GoogleAnalyticsPageView = (props) => {

    useEffect(() => {
        /*
        ReactGA.initialize('UA-186129863-1', {
            testMode: true
        });*/
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    return (<></>);
}

export default GoogleAnalyticsPageView;
