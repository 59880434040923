import React from 'react'
import './index.scss'
import './bootstrap.css'
import './common.scss'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import Product from './pages/Product'
import Features from './pages/Features'
import Navigation from './comp/Navigation'
import Footer from './comp/Footer'
import PageNotFound from './pages/PageNotFound'
import CookieToast from './comp/CookieToast'
import State from './State'
import Contact from './pages/Contact'
import { Lang } from './Lang'
import UseCases from './pages/UseCases'
import ThankYou from './pages/ThankYou'
import GoogleAnalyticsPageView from './comp/GoogleAnalyticsPageView'
import ReactGA from 'react-ga'

export const SupportedLanguages = [
    {
        lang: 'en', 
        label: 'English'
    }
];

class App extends React.Component {

  constructor(props) {
    super(props)
    this.constructState()

    let langCodes = [];
    SupportedLanguages.map((item, index) => {
      langCodes.push(item.lang);
      return 0;
    });
    this.langRegex = '(' + langCodes.join('|') + ')';
  }

  constructState() {
    if (this.props.match.match && this.props.match.match.params) {
      State.lang = this.props.match.match.params[0];
    }
  }

  componentDidMount() {
    ReactGA.initialize('UA-186129863-1', {
      testMode: false
    })
  }

  componentWillMount() {
    Lang.setLanguage(State.lang);
  }

  render() {
    return (
        <div>
          <BrowserRouter>
            <Switch>

              <Route exact path={"/" + this.langRegex + "?/"} children={(match) => (
                <>
                  <GoogleAnalyticsPageView/>
                  <CookieToast/>
                  <Navigation homeActive="active"/>
                  <Product/>
                </>
              )}/>

              <Route exact path={"/" + this.langRegex + "?/UseCases/"} children={(match) => (
                <>
                <GoogleAnalyticsPageView/>
                <CookieToast/>
                <Navigation usesCasesActive="active"/>
                <UseCases/>
                <Footer/>
                </>
              )}/>

              <Route exact path={"/" + this.langRegex + "?/Features/"} children={(match) => (
                <>
                <GoogleAnalyticsPageView/>
                <CookieToast/>
                <Navigation featuresActive="active"/>
                <Features/>
                <Footer/>
                </>
              )}/>

              <Route exact path={"/" + this.langRegex + "?/Contact/"} children={(match) => (
                <>
                <GoogleAnalyticsPageView/>
                <CookieToast/>
                <Navigation contactActive="active"/>
                <Contact/>
                <Footer hideFooter/>
                </>
              )}/>

              <Route exact path={"/" + this.langRegex + "?/ThankYou/"} children={(match) => (
                <>
                <GoogleAnalyticsPageView/>
                <CookieToast/>
                <Navigation/>
                <ThankYou/>
                <Footer/>
                </>
              )}/>

              <Route children={(match) => (
                <>
                <GoogleAnalyticsPageView/>
                <Navigation/>
                <PageNotFound/>
                <Footer/>
                </>
              )} />

            </Switch>
            
          </BrowserRouter>
        </div>
    );
  }
}

export default App;
