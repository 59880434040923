import React from 'react'

const SentimentHeatMap = (data) => {
    let sent = data.sentiment['breakdown']
    return (
      <p>
      {
        data.tokens.map((item, index) => {
            
            let level = 1;
            let combinedScore = sent[index]['compound'];
            if (combinedScore > 0.4) { // positive
              level = 2;
            } else if (combinedScore < -0.4) { // negative
              level = 0;
            }

            if (index === 0) {
              return <span className={`sentiment-heatmap sent level-`+ level} key={index}>{item}</span>
            } else {
              return <span key={index}>&nbsp;<span className={`sentiment-heatmap sent level-`+ level} key={index}>{item}</span></span>
            }
          }
        )
      }
      </p>
    )
  }

  export default SentimentHeatMap