import React, { useState } from 'react'
import Chart from "react-google-charts"
import ModeDropdown from './ModeDropdown'

function dataPointToLabel(value) {
  if (value > 0.75) {
    return 'Discrimination';
  }
  if (value < 0.75 && value > 0.25) {
    return 'Possibly discriminating';
  }
  if (value < 0.25 && value > 0.1) {
    return 'Possibly neutral';
  }

  return 'Neutral';
}

const DiscriminationChart = (props) => {
    const [mode, setMode] = useState('read');

    const durations = props.data[mode + 'Time'].breakdown;
    let chartData = []
    let tmp = 0;
    for (let i=0; i < props.data.toxic.breakdown.length; i++) {
      tmp += durations[i]
      let value = props.data.toxic.breakdown[i]['discrimination']
      chartData.push([tmp, value, props.data.tokens[i] + ' [' + dataPointToLabel(value) + ']'])
    }
    
    chartData.unshift([mode + " time", "Discrimination", {'role': 'tooltip'}])
    return <div style={{'height': '400px'}}>
      <Chart
        width="100%"
        height="400px"
        chartType="SteppedAreaChart"
        loader={<div>Loading Chart</div>}
        data={chartData}
        options={{
          tooltip: {isHtml: true},
          chartArea: {
            left: 120,
            top: 40,
            width: '100%'
          },
          connectSteps: false,
          //areaOpacity: 0,
          colors: ['#5A5BE9'],
          hAxis: {
            title: 'Time (in seconds)',
            format: '#',
          },
          legend: 'none',
          vAxis: {
            title: 'Discrimination level',
            ticks: [
              {v: -0.05, f: ''}, 
              {v: 0, f: 'Neutral'}, 
              {v: 0.5, f: 'Possibly discriminating'}, 
              {v: 1, f: 'Discriminating'},
            ],
          },
        }}
      />
      <ModeDropdown id="discrimination" onSelectCallback={(key) => {setMode(key)}}/>
    </div>
  }

  export default DiscriminationChart;